import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 30%;
    margin-left: 10px;
    position: relative;

    h4 {
        color: var(--dark-grey);
    }

    .flex {
        display: flex;
        gap: 10px
    }

    @media (max-width: 1400px) {
        width: 27%;
    }
`
import styled from "styled-components"

export const DivSelect = styled.div`
    display: block;
    select{
        outline: none;
        width: 320px;
        height: 50px;
        background: var(--white);
        border: 1px solid var(--mid-grey);        
    }
`;

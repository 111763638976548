import styled from 'styled-components';

interface InputProps {
    type?: string;
}

export const Container = styled.label<InputProps>`
    /* border: 1px solid var(--mid-grey); */
    border-radius: 4px;
    height: 50px;
    display: flex;
    align-items: center;
    background: var(--white);
    .title {
        color: white;
        font-size: 12px;
        margin-right: 5px;
    }
    &.file {
        border: none;
        color: transparent;
        input::-webkit-file-upload-button {
            visibility: hidden;
        }
        input::before {
            content: '';
            background: transparent;
            border-radius: 4px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 21px;
            color: #fff;
            cursor: pointer;
            border: none;
        }
        transition: all 0.2s;
        &:hover {
            transform: scale(1.05);
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        }
    }
    input {
        width: 320px;
        height: 50px;
        padding: 10px 15px;
        border-radius: 4px;
        border: 1px solid var(--mid-grey);
        background: var(--white); 
        &:active {
            border: 1px solid var(--bright-purple);
        } 
        &:focus {
            border: 1px solid var(--bright-purple);
        }       
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    @media (max-width: 1400px) {
        width: 190px;
        input {
            width: 100%;
        }
    }
`;
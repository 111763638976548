import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 20px 40px;
    background: rgb(197,152,251);
    background: linear-gradient(51deg, rgba(197,152,251,1) 0%, rgba(212,223,255,1) 100%);    
`

export const ShowInfos = styled.div`
    padding: 20px 0;
    padding-bottom: 0;
    background: var(--background);
    border-radius: 8px;
    position: relative;

    h1 {
       color: var(--mid-purple);
       padding-left: 40px;
    }

    @media (max-width: 1400px) {
        h1 {
            margin-bottom: 30px;
        }
    }
`

export const ContainerTop = styled.div`
    display: flex;
    padding: 20px 40px;
    /* width: fit; */
    height: 25vh;
    position: relative;
    
    .small {
        width: 255px;        
    }

    @media (max-width: 1800px) {
        height: fit-content;

        .small {
            width: 115px;        
        }
    }
`

export const ContainerBottom = styled.div`
    height: 57vh;
    background: var(--white); 
    padding: 0 40px;

    .details {
        height: 40px;
        cursor: pointer;
        padding-left: 10px;
        display: flex;
        align-items: center;

        .circle {
            background: var(--bright-purple);
            border-radius: 50%;
            width: 6px;
            height: 6px; 
            margin: 0 1px;
        }
    }    

    @media (max-width: 1400px) {
        height: 40vh;
    }
`

export const VolumetryFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .filter {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 14px 10px;
        max-width: 100%;
        margin-top: 20px;
        position: relative;
    
        .title {
            position: absolute;
            top: -25px;
            left: 0;
            color: var(--dark-grey);
            font-weight: 600;
        }
    
        select {
            width: 100%;
            max-width: 320px;
            height: 50px;
            padding: 10px 15px;
            border-radius: 4px;
            border: 1px solid var(--mid-grey);
            background: var(--white);
            outline: none;
            &:focus {
                border: 1px solid var(--bright-purple);
            }           
        }
    
        .cleaner {
            height: 50px;
            background: none;
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 15px;
            color: var(--bright-purple);
            font-weight: 500;
            transition: all .2s;
            &:hover {
                border: 1px solid var(--bright-purple);
                
            }
            margin-right: auto;
        }
    
        .volume {
            max-width: 255px;
        }
    }

    @media (max-width: 1800px) {
        .filter {
            display: flex;
            flex-wrap: wrap;
            width: 1000px;

            .volume {
                max-width: 230px;
            }

            select {
                width: 230px;
            }
        }
    }

    @media (max-width: 1600px) {
        .filter {
            display: flex;
            flex-wrap: wrap;
            width: 930px;

            .volume {
                max-width: 215px;
            }

            select {
                width: 215px;
            }
        }
    }

`

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--white);
    padding: 13px 40px;
    border-radius: 0 0 8px 8px;
    .pagination {
        display: flex;
        gap: 5px;
        list-style-type: none;
        .page-link {
            background: none;
            border: none;
            border-radius: 4px;
            padding: 5px 8px;
            font-weight: 500;
            color: var(--dark-grey);
            transition: all .2s;
            cursor: pointer;
            &:hover {
                background: #d5ebe7;
                color: var(--button);
            }
            &.active {
                background: #d5ebe7;
                color: var(--button);
            }
        }
    }

    
    @media (max-width: 1400px) {
        align-self: flex-end;
        padding: 25px 40px;
    }
`

export const Divider = styled.div`
    width: 1px;
    background-color: #D5D5D5;
    align-self: center;
    margin-left: 20px;
    margin-right: 20px;
    height: 85%;

    @media (max-width: 1400px) {
        margin-left: 15px;
        margin-right: 15px;
    }
`

export const StatusCardContainer = styled.div`
    width: 120px;
    height: 160px;
    background: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border-top: 2px solid #8111c7;
    border-radius: 1px 1px 8px 8px;
    -webkit-box-shadow: 1px 5px 10px 0px #C7C7C7; 
    box-shadow: 1px 5px 10px 0px #C7C7C7;

    .title {
        h3 {
            color: #424242;
            font-weight: 600;
        }
    }

    .number {
        span {
            font-size: 40px;

            &.late {
                color: var(--danger);
            }

            &.zero {
                color: var(--dark-green);
            }
        }
        
    }

    .date {
        span {
            color: var(--dark-grey);
            font-size: 12px;
            font-style: italic;
        }
    }

    @media (max-width: 1400px) {
        height: 130px;
    }
`

export const ModalTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 120px;
    border-bottom: 1px solid var(--light-grey);
    padding-left: 20px;

    .data {
        display: flex;
        width: 60%;
        gap: 60px;
        padding-bottom: 20px;
        .client,
        .contract {
            span {
                color: var(--dark-grey);
                line-height: 30px;                
            }
            p {
                color: #474747;
                margin-top: 10px;
                font-weight: 500;
                font-size: 16px;
            }
        }

    }

    .buttons {
        button {
            background: none;
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 15px;
            color: var(--bright-purple);
            font-weight: 500;
            transition: all .2s;
            &:hover {
                border: 1px solid var(--bright-purple);
                
            }
        }
    }
`

export const ModalBottom = styled.div`
    display: flex;
    width: 100%;
    padding-left: 20px;
    padding-top: 20px;

    .scroller {
        width: 100%;
        max-height: 500px;
    }
`

export const ModalLoader = styled.div`
    height: 300px;
`

export const DetailsButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;    

    svg {
        width: 24px;
        height: 24px;
    }
`

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ButtonDefault from "../../components/ButtonDefault/ButtonDefault";
import Filter from "../../components/Filter/Filter";
import InputDefault from "../../components/InputDefault/Input";
import ModalDefault from "../../components/ModalDefault/Modal";
import Status from "../../components/Status/Status";
import Table from "../../components/Table/Table";
import { 
    Container, 
    ContainerBottom, 
    ContainerTop, 
    DetailsButton, 
    Divider, 
    Footer, 
    ModalBottom, 
    ModalLoader, 
    ModalTop, 
    ShowInfos, 
    StatusCardContainer, 
    VolumetryFilter
} from "./styles";
import protheus from "../../services/protheus";
import Select from "../../components/SelectDefault/Select";
// import Paginate from "../../components/Paginate/Paginate"; 
import Loading from "../../components/Loading/Loading";
import ReactPaginate from 'react-paginate';
import { IconDots } from "../../assets/icons";

interface VolumetryProps {
    clientName?: string,
    cnpj?: any,
    contractNumber?: string,
    month?: any,
    year?: any,
    minVolumetry?: string,
    maxVolumetry?: string,
    page: number,
}

interface MachineProps {
   Maquina: string,
   Serie: string,
   Volumetries: [
    {
        MesAno: string,
        Volumetria: string,
    },
    {
        MesAno: string,
        Volumetria: string,
    },
    {
        MesAno: string,
        Volumetria: string,
    },
    {
        MesAno: string,
        Volumetria: string,
    },
    {
        MesAno: string,
        Volumetria: string,
    },
   ]
}

export default function Home() {
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ page, setPages ] = useState<string>('Fatura');
    const [ filter, setFilter] = useState<any>();
    const [ openModal, setOpenModal] = useState<boolean>(false);    
    const [ actualData, setActualData ] = useState<any>();
    const [ volumetryData, setVolumetryData ] = useState<any>();
    const [ zeroData, setZeroData ] = useState<any>();
    const [ delayedData, setDelayedData ] = useState<any>();
    const [ updateData, setUpdateData ] = useState<any>();
    const [ starterDate, setStarterDate ] = useState<any>({
        actualMonth: '',
        actualYear: '',
    })
    const [fiveMonths, setFiveMonths] = useState<any>([]);
    const [ tenYears, setTenYears ] = useState<any>([]);
    const [ userModalInfo, setUserModalInfo ] = useState<any>('');
    const [ userModalMachines, setUserModalMachines ] = useState<MachineProps[]>();
    
    const [DTO, setDTO] = useState<any>({
        name: '',
        cnpj: '',
        contract: '',
        month: starterDate.actualMonth,
        year: starterDate.actualYear,
        status: '',
        Page: 1,
    });

    const [DTOVolumetry, setDTOVolumetry] = useState<VolumetryProps>({
        clientName: '',
        cnpj: '',
        contractNumber: '',
        month: starterDate.actualMonth,
        year: starterDate.actualYear,
        minVolumetry: '',
        maxVolumetry: '',
        page: 1,
    });

    useEffect(() => {
        
        function getTodayDate() {
            const date = new Date();
            let month = date.getMonth()
            let year = date.getFullYear().toString();
            setStarterDate({ actualYear: year, actualMonth: month + 1 })                        
        }
        
        getFiveMonths();
        getTodayDate();     
        if (page === 'Fatura') {

            getActualData(); 
        } else {
            getVolumetry();
        }
        getTenYears();
        
    }, [updateData])    

    const [paginate, setPaginate] = useState<any>({}) 
    const [paginateVolumetry, setPaginateVolumetry] = useState<any>({}) 
    
    function getFiveMonths() {
        
        const month = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dec"];    
        
        const todayDate = new Date();

        let firstMonth = todayDate.getMonth()
        let lastMonth = todayDate.getMonth() - 5

        let lastFiveMonths = [];

        for (let i = firstMonth; i > lastMonth; i--) {
            lastFiveMonths.push(month[i])
        }

        setFiveMonths(lastFiveMonths)
        
    }

    function getTenYears() {

        const date = new Date();
        let actualYear = date.getFullYear();
        let lastYear = date.getFullYear() - 10;
        let lastTenYears = [];

        for (let i = actualYear; i >= lastYear; i--) {
          lastTenYears.push(i)   
        }

        setTenYears(lastTenYears);
       
    }

    function changePage(e: string) {
        if (e === 'Fatura') {
            setPages('Fatura');
        }
        else {
            setPages('Volumetria');
        }        
    }

    async function handleSubmitFilter(e: any) {
        e.preventDefault()

        // getFiveMonths()
        onChangeInput("Page", 1)
        await getActualData()
        setUpdateData('filter')          
       
    }

    const onChangeInput = (key: string, value: string | number) => {
        
        const newDTO: any = DTO
        newDTO[key] = value
        setDTO({ ...newDTO }) 
        
    }

    const changeInputVolumetry = (key: string, value: string | number) => {
        
        const newDTO: any = DTOVolumetry
        newDTO[key] = value
        setDTOVolumetry({ ...newDTO }) 
        
    }

    async function getActualData() {

        try {

            let filters = {
                page: DTO.Page,
                limit: 10,
                month: DTO.month || starterDate.actualMonth,
                year: DTO.year || starterDate.actualYear,
                clientName: DTO.name,
                contractNumber: DTO.contract,
                CNPJ: DTO.cnpj.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
                status: DTO.status
            }

            if(!filters.clientName){
                delete filters.clientName
            }

            if(!filters.contractNumber){
                delete filters.contractNumber
            }

            if(!filters.CNPJ){
                delete filters.CNPJ
            }
            
            if(!filters.status){
                delete filters.status
            }

            setLoading(true)
            const response = await protheus.get('api/protheus/invoices', {params: filters,
                auth: {
                    username: 'admin',
                    password: 'agGRgU&4!f&FwTSe'
                }
            }) 
           
            setActualData(response.data.ClientInvoices);

            const sorter = (a: any, b: any) => {
                if(a.Year !== b.Year){
                    return a.Year - b.Year;
                }
            };

            setDelayedData(response.data.DelayedInvoices.sort(sorter));

            setPaginate({ ...response.data.Pagination });

            setLoading(false);            
            
        } catch (error) {
            setLoading(false);
            
        }
    }

    async function getVolumetry() {

        try {

            let filters = {
                clientName: DTOVolumetry.clientName,
                cnpj: DTOVolumetry.cnpj.replaceAll('.','').replaceAll('-','').replaceAll('/',''),
                contractNumber: DTOVolumetry.contractNumber,
                month: DTOVolumetry.month || starterDate.actualMonth,
                year: DTOVolumetry.year || starterDate.actualYear,
                minVolumetry: DTOVolumetry.minVolumetry,
                maxVolumetry: DTOVolumetry.maxVolumetry,
                page: DTOVolumetry.page,
                limit: 10,                
            }

            if(!filters.clientName){
                delete filters.clientName
            }

            if(!filters.contractNumber){
                delete filters.contractNumber
            }

            if(!filters.cnpj){
                filters.cnpj = null
            }
            
            if(!filters.minVolumetry){
                delete filters.minVolumetry
            }

            if(!filters.maxVolumetry){
                delete filters.maxVolumetry
            }

            setLoading(true);

            const response = await protheus.get('api/protheus/volumetry', {params: filters,
                auth: {
                    username: 'admin',
                    password: 'agGRgU&4!f&FwTSe'
                }
            });
            
            setVolumetryData(response.data.ClientVolumetries);
            setZeroData(response.data.ZeroVolumetries);
            setPaginateVolumetry(response.data.Pagination);           

            setLoading(false)

            
        } catch (error: any) {

            console.log('log error volumetry', error.response.data.Message);

            setLoading(false);
            
        }
        
    }

    async function getMachinesInfo(info: any) {
        try {

            let filters = {
                cnpj: info.CNPJ,
                contractNumber: info.Contrato,
                month: DTOVolumetry.month || starterDate.actualMonth,
                year: DTOVolumetry.year || starterDate.actualYear,                
            }

            setLoading(true);

            const response = await protheus.get('api/protheus/volumetry/machine', {params: filters,
                auth: {
                    username: 'admin',
                    password: 'agGRgU&4!f&FwTSe'
                }
            })
            setUserModalMachines(response.data.MachinesVolumetries);

            setLoading(false);
            
        } catch (error: any) {

            console.log('log error machine info', error.response.data.Message);

            setLoading(false);
            
        }
    }

    const onClickPage = async (selectedItem: { selected: number; }) => {
        let pageNumber = selectedItem.selected + 1
        onChangeInput("Page", pageNumber)
        await getActualData()
    }

    const onClickPageVolumetry = async (selectedItem: { selected: number; }) => {
        let pageNumber = selectedItem.selected + 1
        onChangeInput("Page", pageNumber)
        await getVolumetry()
    }

    function cleanFilters() {
        setDTO('');
        window.location.reload();
        getActualData();

    }      
   
    return(
        <Container>

            <ShowInfos>
                {
                    page === 'Fatura'
                    ? <h1>Fatura</h1>
                    : <h1>Volumetria</h1>
                }
                

                <ContainerTop>
                    {
                        page === 'Fatura'
                            ?   <Filter>
                                    <form onSubmit={handleSubmitFilter}>

                                        <InputDefault
                                            value={DTO.name}
                                            placeholder="Nome do cliente"
                                            onChange={(value) => onChangeInput('name', value)}
                                        />
                                        <InputDefault
                                            value={DTO.contract}
                                            type='number'
                                            placeholder="Nº do Contrato"
                                            onChange={(value) => onChangeInput('contract', value) }
                                        />
                                        <InputDefault
                                            value={DTO.cnpj}
                                            mask="99.999.999/9999-99"
                                            placeholder="CNPJ"
                                            onChange={(value) => onChangeInput('cnpj', value) }
                                        />

                                        <Select
                                            name="status"
                                            value={DTO.status}
                                            onChange={onChangeInput}
                                        >
                                            <option value="Status">Status</option>
                                            <option value="Aberto">Aberto</option>
                                            <option value="Pago">Pago</option>
                                            <option value="Atrasado">Atrasado</option>
                                        </Select>                                        

                                        <Select
                                            name="month"
                                            value={DTO.month || starterDate.actualMonth}
                                            onChange={onChangeInput}
                                        >
                                            <option value="">Mês</option>
                                            <option value="1">Janeiro</option>
                                            <option value="2">Fevereiro</option>
                                            <option value="3">Março</option>
                                            <option value="4">Abril</option>
                                            <option value="5">Maio</option>
                                            <option value="6">Junho</option>
                                            <option value="7">Julho</option>
                                            <option value="8">Agosto</option>
                                            <option value="9">Setembro</option>
                                            <option value="10">Outubro</option>
                                            <option value="11">Novembro</option>
                                            <option value="12">Dezembro</option>
                                        </Select>
                                        
                                        <Select
                                            name="year"
                                            value={DTO.year || starterDate.actualYear}
                                            onChange={onChangeInput}
                                        >
                                            <option value="">Ano</option>
                                            {
                                                tenYears.map((year: any, key: any) => (
                                                    <option value={year} key={key}>{year}</option>
                                                ))
                                            }
                                            
                                        </Select>
                                        
                                        <ButtonDefault
                                            type="submit"
                                            onClick={() => setFilter(DTO)}
                                        >
                                            Filtrar
                                        </ButtonDefault>

                                        <button 
                                            type="button" 
                                            className="cleaner"
                                            onClick={() => cleanFilters()}
                                        >
                                            X Limpar Filtro
                                        </button>

                                    </form>
                                </Filter>
                            :   <VolumetryFilter>
                                    <div className="filter">

                                        <div className="title">
                                            Filtros
                                        </div>

                                        <InputDefault
                                            value={DTOVolumetry.clientName}
                                            className={"volume"}
                                            placeholder="Nome do cliente"
                                            onChange={(value) => changeInputVolumetry('clientName', value)}
                                        />

                                        <InputDefault
                                            value={DTOVolumetry.cnpj}
                                            className={"volume"}
                                            mask="99.999.999/9999-99"
                                            placeholder="CNPJ"
                                            onChange={(value) => changeInputVolumetry('cnpj', value)}
                                        />

                                        <InputDefault
                                            value={DTOVolumetry.contractNumber}
                                            className={"volume"}
                                            type='number'
                                            placeholder="Nº do Contrato"
                                            onChange={(value) => changeInputVolumetry('contractNumber', value)}
                                        />

                                        <InputDefault
                                            value={DTOVolumetry.minVolumetry}
                                            type='number'
                                            className={"volume"}
                                            placeholder="Volumetria Mínima"
                                            onChange={(value) => changeInputVolumetry('minVolumetry', value)}
                                        />

                                        <InputDefault
                                            value={DTOVolumetry.maxVolumetry}
                                            type='number'
                                            className={"volume"}
                                            placeholder="Volumetria Máxima"
                                            onChange={(value) => changeInputVolumetry('maxVolumetry', value)}
                                        />

                                        <Select
                                            name="month"
                                            value={DTOVolumetry.month || starterDate.actualMonth}
                                            onChange={changeInputVolumetry}
                                            className="volume"
                                        >
                                            <option value="">Mês</option>
                                            <option value="1">Janeiro</option>
                                            <option value="2">Fevereiro</option>
                                            <option value="3">Março</option>
                                            <option value="4">Abril</option>
                                            <option value="5">Maio</option>
                                            <option value="6">Junho</option>
                                            <option value="7">Julho</option>
                                            <option value="8">Agosto</option>
                                            <option value="9">Setembro</option>
                                            <option value="10">Outubro</option>
                                            <option value="11">Novembro</option>
                                            <option value="12">Dezembro</option>
                                        </Select>

                                        <Select
                                            name="year"
                                            value={DTOVolumetry.year || starterDate.actualYear}
                                            onChange={changeInputVolumetry}
                                            className="volume"
                                        >
                                            <option value="">Ano</option>
                                            {
                                                tenYears.map((year: any, key: any) => (
                                                    <option value={year} key={key}>{year}</option>
                                                ))
                                            }
                                        </Select>


                                        <ButtonDefault
                                            type="button"
                                            className="small"
                                            onClick={() => getVolumetry()}
                                        >
                                            Filtrar
                                        </ButtonDefault>

                                        <button 
                                            className="cleaner"
                                            onClick={() => {                                                
                                                setDTOVolumetry({
                                                    clientName: '',
                                                    cnpj: '',
                                                    contractNumber: '',
                                                    month: starterDate.actualMonth,
                                                    year: starterDate.actualYear,
                                                    minVolumetry: '',
                                                    maxVolumetry: '',
                                                    page: 1,
                                                })
                                                setUpdateData(new Date())
                                            }}
                                            type={'button'}
                                        >
                                            X Limpar Filtro
                                        </button>
                                    </div>                                    
                                </VolumetryFilter>
                    }                    

                    <Divider />

                    <Status>
                        {   
                            page === 'Fatura' 
                            ?   
                                delayedData?.length > 0 
                                ?                              
                                    delayedData.slice(-3).map((status: any, key: any) => (
                                        <StatusCardContainer key={key}>
                                            <div className="title">
                                                <h3>Atrasados</h3>
                                            </div>

                                            <div className="number">
                                                <span className='late'>{status.Total}</span>
                                            </div>

                                            <div className="date">
                                                <span>
                                                    {
                                                        status.Month === 1
                                                        ? 'Jan'
                                                        : status.Month === 2
                                                        ? 'Fev'
                                                        : status.Month === 3
                                                        ? 'Mar'
                                                        : status.Month === 4
                                                        ? 'Abr'
                                                        : status.Month === 5
                                                        ? 'Mai'
                                                        : status.Month === 6
                                                        ? 'Jun'
                                                        : status.Month === 7
                                                        ? 'Jul'
                                                        : status.Month === 8
                                                        ? 'Ago'
                                                        : status.Month === 9
                                                        ? 'Set'
                                                        : status.Month === 10
                                                        ? 'Out'
                                                        : status.Month === 11
                                                        ? 'Nov'
                                                        : status.Month === 12
                                                        ? 'Dez'
                                                        : ''
                                                    }
                                                    /
                                                    {
                                                        status.Year
                                                    }
                                                </span>
                                            </div>
                                        </StatusCardContainer>
                                    ))
                                :
                                    fiveMonths.slice(0, 3).reverse().map((row: any, key: any) => (
                                        <StatusCardContainer key={key}>
                                            <div className="title">
                                                <h3>Atrasados</h3>
                                            </div>

                                            <div className="number">
                                                <span className='late'>-</span>
                                            </div>

                                            <div className="date">
                                                <span>
                                                    {row}/{starterDate.actualYear}
                                                </span>
                                            </div>
                                        </StatusCardContainer>
                                    ))
                            :       
                                    zeroData?.length > 0
                                    ?   
                                        zeroData.slice(-3).map((status: any, key: any) => (
                                            <StatusCardContainer key={key}>
                                                <div className="title">
                                                    <h3>Zero</h3>
                                                </div>

                                                <div className="number">
                                                    {
                                                        loading
                                                        ? 'Carregando...'
                                                        :   <span className='zero'>{status.Total}</span>
                                                    }
                                                </div>

                                                <div className="date">
                                                    {
                                                        loading 
                                                        ? <span>Carregando</span>
                                                        :   <span>
                                                                {
                                                                    status.Month === 1
                                                                    ? 'Jan'
                                                                    : status.Month === 2
                                                                    ? 'Fev'
                                                                    : status.Month === 3
                                                                    ? 'Mar'
                                                                    : status.Month === 4
                                                                    ? 'Abr'
                                                                    : status.Month === 5
                                                                    ? 'Mai'
                                                                    : status.Month === 6
                                                                    ? 'Jun'
                                                                    : status.Month === 7
                                                                    ? 'Jul'
                                                                    : status.Month === 8
                                                                    ? 'Ago'
                                                                    : status.Month === 9
                                                                    ? 'Set'
                                                                    : status.Month === 10
                                                                    ? 'Out'
                                                                    : status.Month === 11
                                                                    ? 'Nov'
                                                                    : status.Month === 12
                                                                    ? 'Dez'
                                                                    : ''
                                                                }
                                                                /
                                                                {
                                                                    status.Year
                                                                }
                                                            </span>
                                                    }
                                                </div>
                                            </StatusCardContainer>
                                        ))
                                    :   fiveMonths.slice(0, 3).reverse().map((row: any, key: any) => (
                                            <StatusCardContainer key={key}>
                                                <div className="title">
                                                    <h3>Zero</h3>
                                                </div>

                                                <div className="number">
                                                    <span className='zero'>-</span>
                                                </div>

                                                <div className="date">
                                                    <span>
                                                        {row}/{starterDate.actualYear}
                                                    </span>
                                                </div>
                                            </StatusCardContainer>
                                    ))
                        }
                    </Status>
                </ContainerTop>
                
                <ContainerBottom>                    
                    {
                        !loading ?
                                page === "Fatura"
                                ?   <Table>
                                        <thead>
                                            <tr>
                                                <td>Cliente</td>
                                                <td>Nº Contrato</td>
                                                <td>CNPJ</td>
                                                <td>Data Assinatura</td>
                                                {
                                                actualData?.length > 0
                                                    ?   <>
                                                            <td
                                                                className="right"
                                                            >{actualData[0]? actualData[0].Invoices[0].DataEmissao : ''}</td>
                                                            <td
                                                                className="right"
                                                            >{actualData[0]? actualData[0].Invoices[1].DataEmissao : ''}</td>
                                                            <td
                                                                className="right"
                                                            >{actualData[0]? actualData[0].Invoices[2].DataEmissao : ''}</td>
                                                            <td
                                                                className="right"
                                                            >{actualData[0]? actualData[0].Invoices[3].DataEmissao : ''}</td>
                                                            <td
                                                                className="right"
                                                            >{actualData[0]? actualData[0].Invoices[4].DataEmissao : ''}</td>
                                                        </>
                                                    :   <>
                                                            <td>{fiveMonths[4]}/{starterDate.actualYear}</td>                                                                
                                                            <td>{fiveMonths[3]}/{starterDate.actualYear}</td>                                                                
                                                            <td>{fiveMonths[2]}/{starterDate.actualYear}</td>                                                                
                                                            <td>{fiveMonths[1]}/{starterDate.actualYear}</td>                                                                
                                                            <td>{fiveMonths[0]}/{starterDate.actualYear}</td>                                                                
                                                        </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {   actualData?.length > 0 ?
                                                    actualData.map((row: any, key: any) => (
                                                        <tr key={key}>
                                                            <td className="first">{row.Nome.trimEnd()}</td>
                                                            <td>
                                                                {
                                                                    row.Contrato !== ' '
                                                                    ? row.Contrato
                                                                    : '-'
                                                                }
                                                            </td>
                                                            <td>{row.CNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</td>
                                                            <td>
                                                                {
                                                                    row.Inicio !== null
                                                                    ? row.Inicio
                                                                    : '-'

                                                                }
                                                            </td>
                                                            <td 
                                                                className={
                                                                    row.Invoices[0].Status === "ABERTO" 
                                                                        ? 'open' 
                                                                        : row.Invoices[0].Status === "ATRASADO"
                                                                            ? 'late'
                                                                            : 'paid'
                                                                }
                                                            >
                                                                {   
                                                                    row.Invoices[0].ValorTitulo !== ''
                                                                        ? "R$ " + Number(row.Invoices[0].ValorTitulo).toLocaleString('pt-BR', {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })
                                                                        : '-'
                                                                }
                                                            </td>
                                                            <td
                                                                className={
                                                                    row.Invoices[1].Status === "ABERTO"
                                                                        ? 'open'
                                                                        : row.Invoices[1].Status === "ATRASADO"
                                                                            ? 'late'
                                                                            : 'paid'
                                                                }
                                                            >
                                                                {
                                                                    row.Invoices[1].ValorTitulo !== ''
                                                                        ? "R$ " + Number(row.Invoices[1].ValorTitulo).toLocaleString('pt-BR', {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })
                                                                        : '-'
                                                                }
                                                            </td>
                                                            <td
                                                                className={
                                                                    row.Invoices[2].Status === "ABERTO"
                                                                        ? 'open'
                                                                        : row.Invoices[2].Status === "ATRASADO"
                                                                            ? 'late'
                                                                            : 'paid'
                                                                }
                                                            >
                                                                {
                                                                    row.Invoices[2].ValorTitulo !== ''
                                                                        ? "R$ " + Number(row.Invoices[2].ValorTitulo).toLocaleString('pt-BR', {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })
                                                                        : '-'
                                                                }
                                                            </td>
                                                            <td
                                                                className={
                                                                    row.Invoices[3].Status === "ABERTO"
                                                                        ? 'open'
                                                                        : row.Invoices[3].Status === "ATRASADO"
                                                                            ? 'late'
                                                                            : 'paid'
                                                                }
                                                            >
                                                                {
                                                                    row.Invoices[3].ValorTitulo !== ''
                                                                        ? "R$ " + Number(row.Invoices[3].ValorTitulo).toLocaleString('pt-BR', {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })
                                                                        : '-'
                                                                }
                                                            </td>
                                                            <td
                                                                className={
                                                                    row.Invoices[4].Status === "ABERTO"
                                                                        ? 'open last'
                                                                        : row.Invoices[4].Status === "ATRASADO"
                                                                            ? 'late last'
                                                                            : 'paid last'
                                                                }
                                                            >
                                                                {
                                                                    row.Invoices[4].ValorTitulo !== ''
                                                                        ? "R$ " + Number(row.Invoices[4].ValorTitulo).toLocaleString('pt-BR', {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        })
                                                                        : '-'
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                : 
                                                <tr>
                                                    <td>Sem dados</td>
                                                    <td>Sem dados</td>
                                                    <td>Sem dados</td>
                                                    <td>Sem dados</td>
                                                    <td>Sem dados</td>
                                                    <td>Sem dados</td>
                                                    <td>Sem dados</td>
                                                    <td>Sem dados</td>
                                                    <td>Sem dados</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                :   <Table>
                                        <thead>
                                            <tr>
                                                <td>Cliente</td>
                                                <td>CNPJ</td>
                                                <td>Nº Contrato</td>
                                                {
                                                    volumetryData?.length > 0
                                                    ?
                                                        <>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[0].MesAno : ''}
                                                            </td>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[1].MesAno : ''}
                                                                </td>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[2].MesAno : ''}
                                                            </td>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[3].MesAno : ''}
                                                            </td>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[4].MesAno : ''}
                                                                {/* {
                                                                    volumetryData[0]
                                                                    ? volumetryData[0].Volumetries[4].MesAno.includes('03/') 
                                                                        ? volumetryData[0].Volumetries[4].MesAno.replace('03', 'Mar')
                                                                        : ''
                                                                    : ''
                                                                } */}
                                                            </td>
                                                        </>
                                                    : 
                                                        <>
                                                            <td>Mar/23</td>
                                                            <td>Abr/23</td>
                                                            <td>Mai/23</td>
                                                            <td>Jun/23</td>
                                                            <td>Jul/23</td>
                                                        </>
                                                }
                                                <td>Detalhes</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {   
                                                volumetryData &&
                                                volumetryData.length > 0 
                                                ?
                                                    volumetryData.map((row: any, key: any) => (
                                                        <tr key={key}>
                                                            <td className="first">{row.Cliente.toLowerCase()}</td>
                                                            <td>{row.CNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</td>
                                                            <td>{row.Contrato}</td>
                                                            <td>
                                                                {   
                                                                    row.Volumetries[0].Volumetria !== ''
                                                                    ?   row.Volumetries[0].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')  
                                                                    :   'Sem dados'
                                                                }
                                                            </td>
                                                            <td>
                                                                {   
                                                                    row.Volumetries[1].Volumetria !== ''
                                                                    ?   row.Volumetries[1].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')  
                                                                    :   'Sem dados'
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    row.Volumetries[2].Volumetria !== ''
                                                                    ?   row.Volumetries[2].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')  
                                                                    :   'Sem dados'
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    row.Volumetries[3].Volumetria !== ''
                                                                    ?   row.Volumetries[3].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')  
                                                                    :   'Sem dados'
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    row.Volumetries[4].Volumetria !== ''
                                                                    ?   row.Volumetries[4].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')  
                                                                    :   'Sem dados'
                                                                }
                                                            </td>
                                                            <td 
                                                                className="details"
                                                                onClick={() => {setOpenModal(true); setUserModalInfo(row); getMachinesInfo(row)}}
                                                            >
                                                                <DetailsButton>
                                                                    <IconDots />
                                                                </DetailsButton>
                                                            </td>
                                                        </tr>
                                                    ))
                                                : 
                                                    <tr>
                                                        <td>Sem dados</td>
                                                        <td>Sem dados</td>
                                                        <td>Sem dados</td>
                                                        <td>Sem dados</td>
                                                        <td>Sem dados</td>
                                                        <td>Sem dados</td>
                                                        <td>Sem dados</td>
                                                        <td>Sem dados</td>
                                                        <td>Sem dados</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </Table>                            
                            :   
                                <Loading active={loading}/>                                         
                    }
                </ContainerBottom>

                <Footer>
                    {
                        page === 'Fatura' &&
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Avançar >"
                            previousLabel="< Voltar"     
                            onPageChange={onClickPage}
                            pageRangeDisplayed={4}
                            marginPagesDisplayed={1}
                            pageCount={paginate.TotalPage}
                            forcePage={paginate.CurrentPage !== undefined ? paginate.CurrentPage - 1 : 0}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            activeLinkClassName="active"
                        />
                    }

                    {
                        page === 'Volumetria' &&
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Avançar >"
                            previousLabel="< Voltar"     
                            onPageChange={onClickPageVolumetry}
                            pageRangeDisplayed={4}
                            marginPagesDisplayed={1}
                            pageCount={paginateVolumetry.TotalPage}
                            forcePage={paginateVolumetry.CurrentPage !== undefined ? paginateVolumetry.CurrentPage - 1 : 0}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            activeLinkClassName="active"
                        />
                    }
                    
                    {
                        page === "Fatura"
                        ?   <ButtonDefault
                                type="button"
                                onClick={() => {changePage("Volumetria"); getVolumetry()}}
                            >
                                Ver Volumetria
                            </ButtonDefault>
                        :   <ButtonDefault
                                type="button"
                                onClick={() => changePage('Fatura')}
                            >
                                Ver Fatura
                            </ButtonDefault> 
                    }                   

                </Footer>
            </ShowInfos>

            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModal((openModal) => !openModal)}
                openModal={openModal}
            >
                {
                    !loading &&
                    <>
                        <ModalTop>
                            <div className="data">
                                <div className="client">
                                    <span>Nome do Cliente</span>
                                    <p>{userModalInfo?.Cliente}</p>
                                </div>
                                <div className="contract">
                                    <span>Nº do Contrato</span>
                                    <p>{userModalInfo?.Contrato}</p>
                                </div>
                            </div>

                            <div className="buttons"
                                onClick={() => {setOpenModal(false); setUserModalMachines([])}}
                            >
                                <button>
                                    X Fechar
                                </button>
                            </div>
                        </ModalTop>

                        <ModalBottom>
                            <div className="scroller">
                                <Table>
                                        <thead>
                                            <tr>
                                                <td>Máquina</td>
                                                <td>Nº de Série</td>
                                                {
                                                    volumetryData?.length > 0
                                                    ?
                                                        <>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[0].MesAno : '---'}
                                                            </td>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[1].MesAno : '---'}
                                                                </td>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[2].MesAno : '---'}
                                                            </td>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[3].MesAno : '---'}
                                                            </td>
                                                            <td>
                                                                {volumetryData[0]? volumetryData[0].Volumetries[4].MesAno : '---'}                                                
                                                            </td>
                                                        </>
                                                    : 
                                                        <>
                                                            <td>Mar/23</td>
                                                            <td>Abr/23</td>
                                                            <td>Mai/23</td>
                                                            <td>Jun/23</td>
                                                            <td>Jul/23</td>
                                                        </>
                                                                }
                                            </tr>
                                        </thead>
                                    
                                        <tbody>
                                            {
                                                userModalMachines?.map((row: any, key: any) => (
                                                    <tr key={key}>
                                                        <td className="first">{row.Maquina}</td>
                                                        <td>{row.Serie}</td>
                                                        <td>
                                                            {   
                                                                row.Volumetries[0].Volumetria !== ''
                                                                ?   row.Volumetries[0].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')
                                                                :   'Sem dados'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                row.Volumetries[1].Volumetria !== ''
                                                                ?   row.Volumetries[1].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')
                                                                :   'Sem dados'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                row.Volumetries[2].Volumetria !== ''
                                                                ?   row.Volumetries[2].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')
                                                                :   'Sem dados'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                row.Volumetries[3].Volumetria !== ''
                                                                ?   row.Volumetries[3].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')
                                                                :   'Sem dados'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                row.Volumetries[4].Volumetria !== ''
                                                                ?   row.Volumetries[4].Volumetria.replace(/\d(?=(?:\d{3})+$)/g, '$&.')
                                                                :   'Sem dados'
                                                            }
                                                        </td>                                        
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                </Table>
                            </div>
                        </ModalBottom>
                    </>
                }

                {
                    loading &&
                    <ModalLoader>
                        <Loading active={loading}/>
                    </ModalLoader>
                }

            </ModalDefault>

        </Container>
    )
}
interface Props {
  color?: string;
  width?: number;
  height?: number;
}

export const IconDots: React.FC = ({ width = 800, height = 198, color = '#8111C7' }:Props) => (
    <svg 
        width={width} 
        height={height} 
        viewBox="0 0 800 198" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M99.0298 0.00762939C44.2989 0.00762939 0 44.2816 0 98.9875C0 153.693 44.2489 197.992 99.0298 197.992C153.661 197.992 198.01 153.768 198.01 98.9875C198.01 44.2816 153.661 0.00762939 99.0298 0.00762939ZM405.129 0.00762939C350.523 0.00762939 306.099 44.2566 306.099 98.9625C306.099 153.669 350.473 197.967 405.129 197.967C459.785 197.967 504.208 153.743 504.208 98.9625C504.158 44.2815 459.785 0.00762939 405.129 0.00762939ZM701.045 0.00762939C646.339 0.00762939 601.99 44.2815 601.99 99.0125C601.99 153.669 646.264 197.967 701.045 197.967C755.651 197.967 800 153.743 800 99.0125C800 44.2815 755.651 0.00762939 701.045 0.00762939Z" 
            fill={color}
        />
    </svg>

);
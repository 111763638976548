import styled from "styled-components"

export const Container = styled.button`
   display: flex;
   width: 100%;
   max-width: 320px;
   height: 50px;
   border-radius: 4px;
   border: none;
   justify-content: center;
   font-size: 16px;
   line-height: 24px;
   align-items: center;
   cursor: pointer;
   transition: all .2s;
   background: var(--button);
   color: var(--white);
   font-weight: 500;
   &:hover {
      background: var(--secondary);
   }  
   
   @media (max-width: 1400px) {
      max-width: 190px;
    }
`
import styled from 'styled-components';

interface Props {
    openModal: boolean
    width?: string;
}

export const ContainerOverlay = styled.div<Props>`
  background: rgba(0, 0, 0, 0.69);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${props => props.openModal ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

export const Container = styled.div<Props>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75vw;
    max-height: 90vh;
    background: #FEFEFE;
    border-radius: 16px;
    padding: 20px 40px;    
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 100%;
`;
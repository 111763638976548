import styled from "styled-components";

export const Container = styled.div`
    background: whitesmoke;
    position: relative;
    align-self: center;
    
    h4 {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -30px;
        color: var(--dark-grey);
    }
`

export const Filters = styled.div`
    display: flex;
    margin-bottom: 20px;

    form {
        display: flex;
        flex-wrap: wrap;
        gap: 18px 10px;
    }

    select {
        width: 100%;
        max-width: 320px;
        height: 50px;
        padding: 10px 15px;
        border-radius: 4px;
        border: 1px solid var(--mid-grey);
        background: var(--white);
        outline: none;
        &:focus {
            border: 1px solid var(--bright-purple);
        }           
    }

    .month {
        text-transform: capitalize;
    }

    .volume {
        width: 255px;
    }

    .cleaner {
        background: none;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 15px;
        color: var(--bright-purple);
        font-weight: 500;
        transition: all .2s;
        &:hover {
            border: 1px solid var(--bright-purple);
            
        }
    }
    
    @media (max-width: 1800px) {
        form {
            display: grid;
            grid-template-columns: repeat(4, 240px);
            gap: 18px 10px;

            select {
                max-width: 240px;
            }

            .volume {
                max-width: 240px;
            }

            .cleaner {
                align-self: flex-start;
            }
        }
    }

    @media (max-width: 1600px) {
        form {
            display: grid;
            grid-template-columns: repeat(4, 220px);
            gap: 18px 10px;

            select {
                max-width: 220px;
            }

            .volume {
                max-width: 220px;
            }

            .cleaner {
                align-self: flex-start;
            }
        }
    }

    @media (max-width: 1400px) {
        display: flex;
        flex-wrap: wrap;
        gap: 18px 10px;

        select {
            max-width: 190px;
        }
        .volume {
            width: 185px;
        }
    }


`

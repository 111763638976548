import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
/* @font-face {
    font-family: 'Segoe UI';
    src: url() format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
} */
:root {
    --black: #000;
    --dark-purple: #1E1B33;
    --mid-purple: #2E294E;
    --light-purple: #4A427C;
    --bright-purple: #8111c7;
    --dark-green: #00A184;    
    --secondary: #09A5B5;
    --button: #0ABF9E;
    --danger: #DF5454;
    --dark-grey: #7B7B7B;
    --mid-grey: #ADB5BD;
    --light-grey: #C4C4C4;    
    --background: #F5F5F5;
    --white: #FFF;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
}
html {
    @media (max-width: 1080px) {
      font-size: 14px;
    }
    @media (max-width: 720px) {
      font-size: 12px;
    }
}
body {
    background: var(--background);
    color: var(--default);
    -webkit-font-smoothing: antialiased;
}
body, input, textarea, button {
    font-family: 'Segoe UI', sans-serif;
    font-size: 14px;
    font-weight: 400;
    outline: none;
}
h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
}
button {
    cursor: pointer;
}
hr {
    height: 1px;
    margin: 15px 0;
    background-color: #ddd;
    border: none;
}
`
import React from "react"
import { DivSelect } from "./styles"

interface Props {
    name: string
    value: any
    className?: string,
    onChange: (key: string, value: string) => void
    label?: string
    placeholder?: string
    theme?: "default" | "alt"
    readOnly?: boolean
    children: any
}

const Select: React.FC<Props> = (Props) => {

    const onChange = (e: React.FormEvent<HTMLSelectElement>) => {
        let value = e.currentTarget.value
        Props.onChange(Props.name, value)
    }

   
    const content = (
        <label className={Props.className}>

            <select
                value={Props.value}
                onChange={onChange}
                disabled={Props.readOnly}
            >
                {Props.placeholder &&
                    <option disabled value="">{Props.placeholder}</option>
                }
                {Props.children}
            </select>
        </label>
    )

    return (

        <DivSelect className="divSelect">{content}</DivSelect>           
        
    )

}

export default Select
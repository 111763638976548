import { Container, Filters } from "./styles";

export interface FilterProps {
    // clientName: string,
    // contract: number,
    // cnpj: string,
    // month: string,
    // year: number,
    // status: string,
    children: React.ReactNode;
}

export default function Filter({children}: FilterProps) {
     
    return(
        <Container>
            <h4>Filtros</h4>

            <Filters>
                {children}
            </Filters>
            
        </Container>
    )
}
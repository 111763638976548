import axios from "axios"

var baseUrl = ""

if (process.env.NODE_ENV === 'development') {
    baseUrl = "https://amazon-dashboard.littleboatlabs.com.br/"
} else {
    baseUrl = "https://amazon-dashboard.littleboatlabs.com.br/"
}

const protheus = axios.create({
    baseURL: baseUrl,
    headers: {
        APIKey: "amazoncopy_ac936c9a-429c-48e8-ba20-725da9bc836f"
    }
})

export default protheus;
import { Container } from "./styles";

export interface Statusprops {
    children: React.ReactNode;
}

export default function Status({children}: Statusprops) {
    return(
        <Container>
            <h4>Status</h4>

            <div className="flex">
                {children}           
            </div>
           
        </Container>
    )
}
import { useEffect } from "react"
import { LoadingContainer } from "./styles"
import ClipLoader from "react-spinners/ClipLoader";


export default function Loading({
    active = false
}) {

    useEffect(() => {

    }, [active])

    return (
        <>
            {active === true &&
                <LoadingContainer>
                    <ClipLoader color={"#0ABF9E"} loading={true} css={""} size={150} />
                </LoadingContainer>
            }
        </>
    )

}
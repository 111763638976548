import { Container } from './styles';

interface ButtonProps {
    loading?: boolean;
    type?: 'submit' | 'button';
    children: string;
    className?: string;
    onClick?: () => void;
}

const ButtonDefault: React.FC<ButtonProps> = ({
    loading = false,
    children,
    type = 'submit',
    className = 'filter',
    onClick = () => {},
}) => {
    return (
        <Container type={type} className={className} onClick={onClick}>
            {loading ? 'Carregando' : <span>{children}</span>}
        </Container>
    );
};

export default ButtonDefault;
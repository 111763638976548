import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    height: 95%;
    padding: 20px 0;
    background: var(--white);
    position: relative;
    overflow-y: scroll;

    scrollbar-width: thin;         
    scrollbar-color: var(--mid-grey) var(--white);
    &::-webkit-scrollbar {
      width: 8px;
      background: var(--background);
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--mid-grey);
      border-radius: 12px;
    }

    table {
        width: 100%;
        max-height: 40px;
        border-spacing: 0 2px;
        thead,
        tbody {
            tr {
                height: 40px;                
            }
        }
        tbody {
            tr {
               &:nth-child(odd) {
                   background: var(--background);
               }
            }             
        }
        .open {
            color: blue;
            text-align: right;
        }
        .paid {
            color: black;
            text-align: right;
        }
        .late {
            color: var(--danger);
            text-align: right;
        }
        .first {
            padding-left: 10px;
            text-transform: capitalize;
        }
        .last {
            padding-right: 5px;
        }
        .right {
            text-align: right;
            padding-right: 5px;
        }
    }

    
    @media (max-width: 1400px) {
        height: 100%;
    }
`